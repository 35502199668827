<template>
    <div class="advert">
        <div class="content">
            <div class="logo"><img src="../assets/images/swift-flex-white.png" /></div>
            <div class="info">
                <div class="text">Welcome to SwiftFlex, the latest innovation in clear aligners, combining superior service with cutting-edge technology.</div>
                <div class="buttons">
                    <div class="button"><a href="https://swift-dental-public.s3.eu-west-1.amazonaws.com/swift-flex/swift-flex-brochure-v6.pdf" target="_blank">Find out more</a></div>
                    <div class="button"><a href="https://calendly.com/alignermeetingsammie/45" target="_blank">Book a consultation</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .advert
    {
        background: url('../assets/images/swift-flex-background.png');
        background-size: cover;
        background-position: center center;
        padding: 30px;
        display: flex;
        align-items: center;
        border-radius: 10px;

        .logo
        {
            color: $white;
            font-size: 2em;
            line-height: 1;
            margin-bottom: 30px;
            
            img
            {
                width: 200px;
            }
        }

        .text
        {
            font-size: 2.2em;
            color: $white;
            line-height: 1.1em;
            margin-bottom: 30px;
        }

        .button
        {
            padding-top: 0.5em;
            font-size: 0.8em;
        }

        .button a
        {
            background: $swift-mint-blue;
            color: $swift-not-black;
            white-space: nowrap;
            font-size: 1em;
            display: inline-block;
            padding: 0.5em 1.5em;
            border-radius: 100px;
            text-decoration: none;
        }

        @include media-breakpoint-up(xl)
        {
            // min-height: 300px;
            padding: 40px;

            .text
            {
                font-size: 2.2em;
                font-size: 1.6em;
                margin-bottom: 0px;
                margin-right: 40px;
            }

            .info
            {
                display: flex;
            }

            .button
            {
                // padding-top: 0.5em;

                a
                {
                    font-size: 1.1em;
                    display: block;
                    text-align: center;
                }
            }
            
        }
    }
</style>
